html {
    font-size: 62.5%; }

img {
    max-width: 100%; }

body {
    margin: 0;
    font-family: 'Roboto Slab',sans-serif;
    line-height: 1.5;
    font-size: 2.4rem;
    min-width: 30rem;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center top;
    background-color: #283033; }

*, ::after, ::before {
    box-sizing: border-box; }

$break-sm: 577px;
$break-md: 769px;
$break-lg: 993px;
$break-ip: 1025px;
$break-xl: 1201px;
$break-xx: 1550px;

@mixin respond($size) {
    @media screen and (min-width: #{$size}) {
        @content; } }
a {
    display: inline-block;
    text-decoration: none; }

h1 {
    font-size: 24px;
    font-weight: 400;
    @include respond($break-lg) {
        font-size: 36px; } }

h2 {
    font-weight: bold;
    color: #161616;
    font-size: 24px;
    letter-spacing: .2em;
    text-transform: uppercase;
    margin-bottom: 40px; }
p {
    font-size: 14px;
    line-height: 2;
    margin-bottom: 36px;
    @include respond($break-lg) {
        font-size: 18px; } }


a {
    color: #283033;
    font-size: 14px;
    transition: all .3s linear;
    @include respond($break-lg) {
        font-size: 18px; }
    &:hover {
        color: #4e5d63; } }
#main-wrapper {
    min-height: 100vh;
    background-image: url("../images/back.jpg");
    background-position: center top;
    background-size: cover;
    padding-top: 82px;
    padding: 30px 16px 30px;
    @include respond($break-lg) {
        padding: 0;
        padding-top: 82px; } }

.page {

    &__title {

        color: #fff;
        text-align: center;
        max-width: 1024px;
        margin: 0px auto 28px;
        font-size: 34px; }


    &__subtitle {
        max-width: 912px;
        margin: 0px auto 28px;
        text-align: center;
        @include respond($break-lg) {
            margin: 0 auto 114px; }
        p {
            text-transform: uppercase;
            color: #d9c4a0; } } }


.form {
    .form-group {
        &:after {
            clear: both;
            content: "";
            display: block; } }

    &__title {
        text-align: center;
        margin-bottom: 22px;
        color: #283033;
        font-size: 24px;
        font-weight: 500;
        text-shadow: 0px 0px 27px rgba(228, 215, 194, 1);
        @include respond($break-lg) {
            font-size: 36px;
            margin-bottom: 42px; } }
    &__wrapper {
        padding: 30px 8px;
        background-color: #eee;
        background-image: url("../images/form.jpg");
        background-position: center center;
        background-size: cover;
        @include respond($break-lg) {
            padding: 82px 178px;
            max-width: 912px;
            margin: 0 auto; } }




    label {
        font-size: 16px;
        color: #283033; }
    input[type="text"], input[type="email"] {
        border-radius: 3px;
        border: 1px solid transparent;
        transition:  all .3s linear;
        background-color: #c3ab81;
        box-shadow: 0px 5px 6px 0px rgba(71, 51, 32, 0.25), 0px 9px 18px 0px rgba(121, 95, 51, 0.35) inset;
        font-size: 14px;
        color: #283033;
        padding: 10px 12px;
        @include respond($break-sm) {
            font-size: 18px;
            padding: 20px 30px; }
        &:focus {
            border: 1px solid #2b3336; }
        &:active {
            background-color: #c3ab81; } }

    textarea {
        border-radius: 3px;
        border: 1px solid transparent;
        transition:  all .3s linear;
        background-color: #c3ab81;
        box-shadow: 0px 5px 6px 0px rgba(71, 51, 32, 0.25), 0px 9px 18px 0px rgba(121, 95, 51, 0.35) inset;
        font-size: 14px;
        color: #283033;
        padding: 10px 12px;
        @include respond($break-sm) {
            font-size: 18px;
            padding: 20px 30px; }
        &:focus {
            border: 1px solid #2b3336;
            background-color: #c3ab81;
            box-shadow: 0px 5px 6px 0px rgba(71, 51, 32, 0.25), 0px 9px 18px 0px rgba(121, 95, 51, 0.35) inset; } }


    button[type="submit"] {
        width: 100%;
        border-radius: 3px;
        border: none;
        box-shadow:  0px 8px 16px 0px rgba(34, 34, 34, 0.33);
        font-size: 18px;
        background-color: #283033;
        padding: 20px 30px;
        color: #c3ab81;
        margin-top: 22px;
        text-transform: uppercase; } }






.w-35 {
    @include respond($break-sm) {
        width: 35%; } }
.w-65 {
    @include respond($break-sm) {
        width: 65%; } }


.custom-file-input {
    display: none; }
.custom-file-label {
    position: relative;
    margin-top: 7px;
    padding: 4px 8px;
    text-align: center;
    font-size: 15px !important;
    height: auto;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.08);
    border: 1px dashed #726b59;
    width: 100%;
    &:after {
        display: none; }
    background-image: url("../images/back-3.jpg");
    background-size: auto;
    background-position: center center;
    background-repeat: repeat;
    &.selected {
        background-image: none; }
    @include respond($break-lg) {
        padding: 4px 22px; } }


.label[for="customFile"] {
    margin-right: 16px; }
